<template>
  <div>
    <vx-card ref="filterCard" :title="$t('Filters')" class="user-list-filters mb-8 p-4" collapse-action>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("AdTitle") }}</label>
          <vs-input v-model="search.Title" style="margin-top: 10px;" class="w-full" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("Address") }}</label>
          <vs-input v-model="search.Address" style="margin-top: 10px;" class="w-full" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">السعر من</label>
          <vs-input type="Number" v-model="search.PriceFrom" style="margin-top: 10px;" class="w-full" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">السعر الى</label>
          <vs-input type="Number" v-model="search.PriceTo" style="margin-top: 10px;" class="w-full" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("AdsPublishStatus") }}</label>
          <!-- <vs-input v-model="search.Description" style="margin-top: 10px;" class="w-full" /> -->
          <v-select label="NameAr" class="mt-2 w-full" style="margin-top: 10px;" name="NameAr"
          v-model="search.PublishStatusId" :reduce="model => model.ID" :options="Status" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("AdsCatgeory") }}</label>
          <v-select label="NameAr" class="mt-2 w-full" style="margin-top: 10px;" name="NameAr"
            v-model="search.AdsCategoryId" :reduce="model => model.ID" :options="AdsCatgeory" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("Type") }}</label>
          <v-select label="NameAr" class="mt-2 w-full" style="margin-top: 10px;" name="NameAr"
            v-model="search.AdsTypeID" :reduce="model => model.ID" :options="AdsTypes" />
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75" style="font-weight:bold;">{{ $t("City") }}</label>
          <v-select label="NameAR" class="mt-2 w-full" style="margin-top: 10px;" name="NameAR" v-model="search.CityID"
            :reduce="model => model.Id" :options="Citys" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4" style="margin-top: 30px; display: flex; flex-direction: row; justify-content: space-between;">
          <vs-button color="green"
            style="box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);font-weight: bolder;margin: 10px;width:100px"
            @click="handleSearch()">{{ $t("Search") }}</vs-button>
          <vs-button color="red"
            style="box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);font-weight: bolder;margin: 10px;width:100px"
            @click="GoBack()">{{ $t("رجوع") }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div class="card-container flex flex-wrap justify-center items-center">
  <div v-for="(card, index) in Ads" :key="index" class="card bg-white shadow-lg rounded-lg p-0 m-4"
    style="width: 20%; height: 400px; display: flex; flex-direction: column;">

    <!-- الشريط العلوي مع الحالة "مميز" أو "مروج" في أعلى البطاقة -->
    <div class="card-header flex items-center justify-center"
      :style="[getCardHeaderStyle(card), {height: '20px'}]"> <!-- تحديد ارتفاع ثابت للشريط العلوي -->
      <span v-if="card.IsFeatured && card.IsRecommended" style="color: black; font-weight: bold;">مروج مميز</span>
      <span v-else-if="card.IsFeatured" style="color: black; font-weight: bold;">مميز</span>
      <span v-else-if="card.IsRecommended" style="color: black; font-weight: bold;">مروج</span>
      <!-- الشريط العادي -->
      <span v-else style="color: #C4C1C1; font-weight: bold;"></span>
    </div>

    <div class="scrolling-content flex flex-col h-full">
      <div class="img-container flex items-center justify-center" style="height: 50%;">
        
        <img v-if="card.ImageUrl"  :src="formatImageUrl(card.ImageUrl)" class="img-thumbnail" />
        <!-- <img v-if="card.ImageUrl"  :src="`${baseURL}${card.ImageUrl}`" class="img-thumbnail" /> -->
        <p v-else>{{ $t("NoImg") }}</p>
        
      </div>

      <div class="card-body text-gray-700 flex-grow  " title="الوصف" style="padding-right: 8px;">{{ truncatedDescription(card.Title) }}</div>

      <div class="card-footer text-gray-500 p-2 " style="margin-bottom: 40px;">
        <div title="الموقع" class="flex items-center">
          <i class="feather icon-map-pin mr-2" style="color: #2196F3;"></i>
          <span style="margin-right: 10px;">{{card.Region.NameAR}}-{{card.City.NameAR}}</span>
        </div>

        <div class="flex items-center" style="display: inline;">
          <grid-icon size="1.0x" style="color: #2196F3;" class="custom-class"></grid-icon>
          <span title="فئة الاعلانات" style="margin-right: 5px; margin-left: 10px;">{{ card.Category.NameAr }}</span>
        </div>

        <div class="flex items-center" style="display: inline;">
          <i title="عدد المشاهدات" class="feather icon-eye mr-2" style="color: #2196F3;"></i>
          <span v-if="card.ViewsCount >= 1" title="عدد المشاهدات" style="margin-right: 5px; margin-left: 10px;">{{ card.ViewsCount }}</span>
          <span v-else title="عدد المشاهدات" style="margin-right: 5px; margin-left: 10px;">0</span>
        </div>

        <div class="flex items-center" title="تفاصيل" style="text-align: left;">
          <vs-button color="#48c7da" class="mr-4" @click="openDetails(card.ID)" style="margin-right: auto;">
            <i class="feather icon-info mr-2" style="color: white; font-size: 18px;"></i>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</div>

  </div>
</template>


<script>
import { domain } from "@/gloabelConstant.js";
import moduleProperty from "@/store/Property/moduleProperty.js";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";
import moduleCity from "@/store/settings/City/moduleCity.js";
import { GridIcon } from 'vue-feather-icons';
//import { ShoppingCartIcon } from 'vue-feather-icons'

export default {
 
  components: {
    GridIcon,
    //ShoppingCartIcon

  },
  data() {

    return {
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      Brokers: [],
      search: {
        Name: "",
        Street: "",
        Descraption: "",
        DownPayment: 0.0,
        Longitude: "",
        Latitude: "",
        StreetID: null,
        TypeID: null,
        CategoryID: null,
        CountryID: null,
        ForSale: false,
        ForRent: false,
        PropertyStatusID: 0,
        PostlCode: "",
        ViewTypeID: 0,
        IsHaveRefrigerator: false,
        IsHaveHeater: false,
        IsHaveSuana: false,
        IsHaveWIFI: false,
        IsHaveTV: false,
        IsHaveMicrowave: false,
        IsHaveAirConditioner: false,
        pageNumber: 1,
        pageSize: 10,
        PropertyCategoryId: 0,
        PriceFrom: 0.0,
        Address: "",
        PriceTo: 0.0,
        Size: 0,
        YearBuildFrom: 0,
        YearBuildTo: 0,
        BedroomCountFrom: 0,
        BedroomCountTo: 0,
        BathroomCountFrom: 0,
        BathroomCountTo: 0,
        PersonCount: 0,
        GarageCount: 0,
       // PublishStatusId: 0,
        dateFrom: "",
        dateTo: ""
      },
    
    };
  },
  computed: {
    Status() {
      debugger;
      console.log("statussssssssss",this.$store.state.PropertyList.Status)
      return this.$store.state.PropertyList.Status;
    },
    Ads() {
      debugger;

      console.log("ads",this.$store.state.PropertyList.Propertys);
      return this.$store.state.PropertyList.Propertys;

    },
    
    AdsCatgeory() {
      debugger;
      return this.$store.state.AdsCatgeoryList.AdsCatgeorys;
    },
    AdsTypes() {
      debugger;
      return this.$store.state.PropertyList.AdsTypes;
    },
    Citys() {
      debugger;
      return this.$store.state.CityList.Citys;
    },
  },
  methods: {
    formatImageUrl(imageUrl) {
    if (!imageUrl) return `${this.baseURL}/Uploads/empty-image.png`;

    // تنظيف الرابط من علامات الاقتباس الزائدة
    imageUrl = imageUrl.replace(/^"|"$/g, ""); // إزالة " في البداية والنهاية
    imageUrl = imageUrl.replace(/%22/g, ""); // إزالة أي %22 (تمثيل لعلامة الاقتباس)

    // تصحيح أي "//" غير ضرورية
    let fixedUrl = `${this.baseURL}${imageUrl.replace(/\/{2,}/g, "/")}`;

    console.log("Formatted Image URL:", fixedUrl);
    return fixedUrl;
  },
    openDetails(id) {
      this.$router.push(`/CustomerAds/AdsDetails/${id}`);
    },
    getCardHeaderStyle(card) {
    if (card.IsFeatured && card.IsRecommended) {
      return { backgroundColor: '#f4cccc' };  // مروج ومميز - أخضر فاتح
    } else if (card.IsFeatured) {
      return { backgroundColor: '#FFFF99' };  // مميز - أصفر
    } else if (card.IsRecommended) {
      return { backgroundColor: '#E5FFCC' };  // مروج - برتقالي
    }
    else
    {
      return { backgroundColor: '#eeeeee' };
    }
   
  },
    GoBack() {
      this.search.CategoryID = null,
        this.search.Address = '';
      this.search.PriceFrom = 0.0,
        this.search.PriceTo = 0.0,
        this.search.CountryID = null,
        this.search.CityID = null,
        this.search.AdsTypeID = null,
        this.search.StreetID = null,
        this.search.Title = "",
        this.search.Description = "",
        this.search.pageNumber = 0,
        this.search.pageSize = 0,
        this.search.PublishStatusId = null,
        this.$store.dispatch("PropertyList/fetchDataListItems");
    },
    truncatedDescription(description) {
      const words = description.split(' ');
      if (words.length > 5) {
        return words.slice(0, 5).join(' ') + '...';
      }
      return description;
    },
    handleSearch() {
      debugger;
      this.$store
    .dispatch("PropertyList/SearchPropertysData", this.search)
    .then((res) => {
      this.$store.commit("PropertyList/SET_Propertys", res.data.Data);
      
      // تأخير طباعة البيانات حتى تتحدث في Vuex
      this.$nextTick(() => {
        console.log("🔍 Updated Vuex Propertys:", this.$store.state.PropertyList.Propertys);
        this.$forceUpdate();
      });
    });
    },
    deleteData(data) {
      this.$store.dispatch("PropertyList/removeItem", data).then(() => {
        this.$store.dispatch("PropertyList/fetchDataListItems");
      })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("PropertyList/fetchDataListItems");
        });
    },
    editData(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm",
        text: "Are you sure you want to delete this record ?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        time: 8000,
        color: "success",
        title: "Deleted record",
        text: "Successfully Deleted",
      });
    },
  },
  created() {
    if (!moduleProperty.isRegistered) {
      this.$store.registerModule("PropertyList", moduleProperty);
      moduleProperty.isRegistered = true;
    }
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("AdsCatgeoryList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("PropertyList/GetAllStatus").then(() => {
        console.log("Status Loaded:", this.$store.state.PropertyList.Status);
    }).catch(error => {
        console.error("Error Loading Status:", error);
    });
    this.$store.dispatch("PropertyList/fetchDataListItems");
    this.$store.dispatch("PropertyList/GetAllAdsTypes");
    this.$store.dispatch("CityList/fetchDataListItems");
    this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");

  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style>
.img-thumbnail {
  object-fit: cover;
  width: 123%;
  height: 100%;
}

.img-container {
  width: 100%;
  height: 50%;
}

.card {
  overflow: hidden;
}

.enhanced-search-button:hover {
  background: linear-gradient(to right, #feb47b, #ff7e5f);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
</style>
